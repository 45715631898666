<template>
  <div>
    <DataEntryRequest />
  </div>
</template>

<script>
import DataEntryRequest from '@/infdb_components/DataEntryRequest.vue'
export default {
  components: {
    DataEntryRequest
  }
}
</script>