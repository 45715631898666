<template>
  <div>
    <SysAdmin />
  </div>
</template>

<script>
//import SignIn from '@/infdb_components/SignIn.vue'
import SysAdmin from '../infdb_components/SysAdmin.vue'
export default {
  components: {
    SysAdmin
  }
}
</script>