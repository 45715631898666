<template>
  <div>
    <FarmersStatsWard />
  </div>
</template>

<script>
import FarmersStatsWard from '@/infdb_components/FarmersStatsWard.vue'
export default {
  components: {
    FarmersStatsWard
  }
}
</script>