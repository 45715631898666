<template>
  <Layout is-demo>
    <h1 class="text-h4 ms-0 mb-1">Dashboard</h1>
    <v-alert
      dense
      text
    >
      You can only operate and view data within your coverage area
      <!--<ul>
        <li>You can only operate and view data within your coverage area</li>
        <li>All enteries you make will be save against you. Make sure only true farmer with correct information are captured</li>
      </ul>-->
    </v-alert>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-bold green--text">My Account Status</v-card-subtitle>
      <v-divider></v-divider>

      <v-card-text><b>State(s):</b> {{ work_state }}</v-card-text>
      <!--<div>
        <v-card-text><b>Account Expiry Date:</b> {{ expiryDate | moment("dddd, MMMM Do YYYY") }}</v-card-text>
        <v-card-text><b>Remaining Days:</b> {{ expiryDate | moment("from", "now", true) }}</v-card-text>
      </div>-->
    </v-card>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-bold green--text">My Operations Summaries</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text><b>Registered Com. Stakeholders:</b> {{ cStakeholders }}</v-card-text>
      <v-card-text><b>Registered Data Clerks:</b> {{ dataClerks }}</v-card-text>
      <!--
      <v-card-text><b>Registered farmers:</b> {{ farmers }}</v-card-text>
    -->
    </v-card>
  </Layout>
</template>

<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
import moment from 'moment'

export default {
  components: {
    Layout
  },
  data() {
    return {
      states:'...',
      startDate: '',
      expiryDate:'',
      farmerId:'',
      cStakeholders: '...',
      dataClerks: '...',
      farmers: '...',
      hasActiveAccount: false,

      alterLOBFlag: false,

      userData: {},
      dashboardData: {
        phone: 'loading...',
        email: 'loading...',
        memberId:'loading...',
        surname:'loading...'
      }
    }
  },
  created() {
    this.userData = httpServ.getSessionJson('userData')
    //console.log(this.userData)
    this.work_state = this.userData.work_state
    this.admin_id = this.userData.admin_id
    this.expiryDate = this.userData.expiry_date 
    const today = moment(new Date())
    const expiry_date = moment(this.expiry_date)

    this.hasActiveAccount = moment(expiry_date).isAfter(today, 'day')
    this.getDashboardData()
  },
  methods:{
    getDashboardData: function() {
      const formUrl = httpServ.getNAGSAPUrl('/b-aut/formOptions/getDashboardSummary')
      const resp = httpServ.postFetch(formUrl,{ 
        'stakeholder_id':this.userData.stakeholder_id
      })
      //const resp = httpServ.makeRequest(formUrl, 'GET', { stakeholder_id: this.userData.stakeholder_id })
      
      resp.then((result) => {
        //console.log('data:', result.data)
        if (result.status === 200) {
          this.dataClerks = result.data.dataClerks[0].amount
          this.cStakeholders = result.data.cStakeholders[0].amount
        } else {
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
    }
  }
}
</script>

<style scoped>
.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 32px;
  color: green;
}
.v-alert {
  color: green !important;
}
.card-header-text {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height {
  min-height: 500px !important;
}

::v-deep .v-data-table td {
  height: 20px !important;
  font-size: 14px !important;
}

::v-deep .v-data-table th {
  font-size: 12px !important;
}

.reg-guide-text {
  font-size: 13px !important;
}
</style>
