<template>
  <div>
    <Stakeholder />
  </div>
</template>

<script>
import Stakeholder from '@/infdb_components/Stakeholder.vue'
export default {
  components: {
    Stakeholder
  }
}
</script>