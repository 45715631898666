<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Commodity Stakeholder</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.description="{ item }">
              <span>{{ item.description | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                      color="primary"
                      dark
                      class="ma-1 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add Commodity Stakeholder
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="name"
                              label="Name*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="stakeholder_type_rf"
                              return-object
                              :items="stakeholder_type_arr"
                              item-text="title"
                              item-value="id"
                              label="Stakeholder Type*"
                              :rules="[v => !!v || 'Stakeholder Type is required']"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="description"
                              label="Description*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
              <v-icon class="mr-2" @click="editItem(item)" >
                mdi-file-document-edit
              </v-icon> 
              <!--<v-icon class="mr-2" @click="editPrimaryStatus(item)" >
                mdi-tree
              </v-icon>-->
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
      
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>
  
<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
  
export default {
  components: {
    Layout
  },
  data() {
    return {
      snackbar: false,
      snackbartext:'',
      formTitle:'New Stakeholder Addition',
      valid:'',
      mode:'new',

      farmerData:{},
      village:'...',
      first_name:'...',
      record_count:'',
      selected_commodity:'',
      selected_commodity_rf:'',

      dialog: false,
      dialog2: false,
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'This table has no record!',

      stakeholder_type_rf:'',
      stakeholder_type_arr:{},
      name:'',
      description:'',
      stakeholder_id: '',
      table_id:'',

      commodity_disabled:false,
      verifyBtnLoader: false,
      formDisabled: true,
      formBtnLoader: false,
      formDisabled2: true,
      formBtnLoader2: false,

      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Description', value: 'description' },
        { text: 'Type', value: 'stakeholder_type' }, 
        { text: 'Modify', value: 'actions', sortable: false }
      ],
      tableData: [],
      editedIndex: -1,
      nameRules: [
        (v) => !!v || 'Location is required',
        (v) => (v && v.length >= 2) || 'Location must be greater than 2 characters'
      ],
      clusterRules: [
        (v) => !!v || 'Cluster is required',
        (v) => (v && v.length >= 2) || 'Cluster must be greater than 2 characters'
      ],
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) => (v  &&  Date.parse(v.replaceAll(' ',''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
      ],
      numberRules: [
        (v) => !!v || 'Farm size is required',
        (v) => (!isNaN(parseFloat(v))) || 'Farm size must be a number',
        (v) => (v && v > 0 ) || 'Famr size must greater than zero'
      ]
    }
  },
  created() {

    this.userData = httpServ.getSessionJson('userData')
    this.getFormOption()
    this.getTableData()

    //console.log(this.userData)
  },
  mounted() {},
  methods: {
    isEditOk: function(item) {
      if (item.approval_status < 3) {
        return true
      }  else {
        return false
      }
    },
    getFormOption: function() {
      const formUrl = httpServ.getNAGSAPUrl('/b-aut/formOptions/coordinator/commodityStakeholder')
      const resValue = httpServ.makeRequest(formUrl, 'GET', {})

      resValue.then((result) => {
        if (result.status === 200) {

          this.stakeholder_type_arr = result.data.commodityStakeholder
          //this.farmer_id = result.data.
        }
      })
    }, 
    getTableData: function() {
      const formUrl = httpServ.getNAGSAPUrl('/b-aut/coordinator/commodityStakeholderList')
      const resValue = httpServ.postFetch(formUrl,{ 
        'stakeholder_id':this.userData.stakeholder_id
      })
      /*const resValue = httpServ.makeRequest(formUrl, 'GET', { 
        'stakeholder_id':this.userData.stakeholder_id
      })*/
      
      resValue.then((result) => {
        if (result.message === 'Request Ok') {
          this.tableData = result.data.stakeholders
          this.record_count = 1
        }
        if (result.message === 'No record!') {
          this.record_count = 0
        }
        //console.log(this.tableData)
      })
      this.tableLoading = false
    },
    getNewData: function () {
      const data = {
        status: 'new',
        stakeholder_type_rf: this.stakeholder_type_rf.id,
        name: this.name,
        description: this.description,
        stakeholder_id:this.userData.stakeholder_id,
        state_rf:this.userData.state_rf
      }

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        stakeholder_type_rf: this.stakeholder_type_rf,
        name: this.name,
        description: this.description,
        data_id:this.table_id
      }

      return data
    },
    postData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()
      
      //validate
      if (this.$refs.form.validate()) {
        this.formBtnLoader = true 
    
        const formUrl1 = httpServ.getNAGSAPUrl('/b-aut/coordinator/saveCommodityStakeholder')
        const formUrl2 = httpServ.getNAGSAPUrl('/b-aut/coordinator/updateCommodityStakeholder')
        const url = this.mode === 'new' ? formUrl1 : formUrl2
        const resp = httpServ.postFetch(url,postData)

        resp.then((result) => {
          this.formBtnLoader = true 
          if (result.status === 200) {
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.formBtnLoader = false
            this.getTableData()
          } else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
          this.formBtnLoader = false
        })
      } else {
        console.log('error')
      }
    },
    postData2: function () {
      const postData = {
        commodity_rf: this.selected_commodity_rf,
        phone: this.farmerData.phone
      }
      const formUrl = httpServ.getNAGSAPUrl('/enumeration/stateCoor/stakeholder/tableData')
      const resp = httpServ.postFetch(formUrl,postData)

      resp.then((result) => {
        if (result.status === 200) {
          this.formBtnLoader = false
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.close2()
          this.getTableData()
          
        } else if (result.status === 422) {
          this.formBtnLoader = false
          if (result instanceof SyntaxError) {
            // Unexpected token < in JSON
            //console.log('There was a SyntaxError', result)//checker
            this.snackbartext = result.message
            this.snackbar = true
          } else {
            //console.log('There was an error', result) //Checker
            this.snackbartext = result.message
            this.snackbar = true
          }
          this.formBtnLoader = false
        } else {
          this.formBtnLoader = false
          this.snackbartext = result.message 
          this.snackbar = true
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      this.stakeholder_type_rf = item.stakeholder_type_rf
      this.name = item.name
      this.description =  item.description
      this.table_id = item.id

      this.dialog = true
      this.mode = 'update'
    },   
    editPrimaryStatus(item) {
      //console.log(item)
      this.selected_commodity = item.commodity
      this.selected_commodity_rf = item.commodity_rf
      this.dialog2 = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
      //this.editedItem = Object.assign({}, this.defaultItem)
        this.selected_commodity_rf = ''
        this.name = ''
        this.description =  ''
        this.mode = 'new'
      })
    },
    close2() {
      this.selected_commodity = ''
      this.selected_commodity_rf = ''
      this.dialog2 = false
    }
  }
}
</script>
  
  <style scoped>
  </style>
