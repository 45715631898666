import { render, staticRenderFns } from "./FarmersStatsWard.vue?vue&type=template&id=152353c6&scoped=true&"
import script from "./FarmersStatsWard.vue?vue&type=script&lang=js&"
export * from "./FarmersStatsWard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152353c6",
  null
  
)

export default component.exports